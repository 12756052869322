import {
  OrderType,
  OrderTypeCode,
  DocketItem,
  Docket,
  DocketStatus,
  DocketItemStatus,
  SalesChannel,
  SALES_CHANNEL,
  App,
  TransferInfo,
} from '@oolio-group/domain';
import theme from '../../common/theme';
import {
  DocketDisplayItemType,
  DEFAULT_DOCKET_NAME,
  DocketDisplayOrderItem,
  DocketDisplayItem,
  DocketDisplayBaseItem,
} from '../../common/types';

import {
  DocketDisplayItemTypeHeight,
  RECALL_TEXT,
  CONTINUE,
  VOIDED_TEXT,
  DOCKET_HEADER_COLOR,
  RECALLED_TEXT,
  HeaderHeight,
  FooterHeight,
  TextCharacterCount,
  TextLineHeight,
} from '../../common/constants';
import { removeExtraSpaces } from '../../utils/general';
import { startCase, partition, groupBy } from 'lodash';

/*
 example
 input-1 @orderNumber 'SP-6-10-20220815' to be formatted to output SP-6-10
 input-2 @orderNumber 'SP-6-10-20220815-1' to be formatted to output SP-6-10-1

 @example2 for online orders format is
 input-1 @orderNumber 'OL-6-20220815' to be formatted to output OL-6
*/

const groupTransfers = (docketItems: DocketItem[]) => {
  return Object.values(
    groupBy(
      docketItems,
      item =>
        `${item?.transferInfo?.toTableId}#${item?.transferInfo?.transferredBy}`,
    ),
  ) as DocketItem[][];
};

export function minifyOnlineOrderNumber(orderNumber: string): string {
  const splitedOrderNumber = orderNumber.split('-');
  const onlineOrderNum: string =
    (splitedOrderNumber?.[0] || '') + '-' + (splitedOrderNumber?.[1] || '');
  return onlineOrderNum;
}

export function minifyPosOrderNumber(orderNumber: string): string {
  const splitedOrderNumber = orderNumber.split('-');
  const prefix: string = splitedOrderNumber[0] || '';
  let result = prefix;

  if (splitedOrderNumber?.length > 1) {
    const secondPart = splitedOrderNumber[1].replace(/\D/g, '') || '';
    const thirdPart = splitedOrderNumber[2] || '';
    const fourthPart = splitedOrderNumber[4] ? `-${splitedOrderNumber[4]}` : '';
    result = `${prefix}${secondPart}-${thirdPart}${fourthPart}`;
  }

  return result;
}

export function minifyOrderNumber(orderNumber: string): string {
  if (orderNumber && orderNumber.includes('OL')) {
    return minifyOnlineOrderNumber(orderNumber);
  } else if (orderNumber) {
    return minifyPosOrderNumber(orderNumber);
  }
  return orderNumber;
}

export const constructDisplayDocketHeader = (
  docket: Docket,
): DocketDisplayItem[] => {
  const displayDocket = [];

  const orderInfo = {
    id: docket.id,
    type: DocketDisplayItemType.DOCKET_HEADER,
    name: DEFAULT_DOCKET_NAME,
    orderNumber:
      docket.integrationInfo?.channelOrderDisplayId ||
      minifyOrderNumber(docket.orderNumber),
    orderType: docket?.orderType?.id,
    time: docket.createdAt,
    height: DocketDisplayItemTypeHeight[DocketDisplayItemType.DOCKET_HEADER],
    section: docket?.section?.name,
    table: docket?.table?.name,
    customer: docket?.customer?.name,
    salesChannel: docket?.salesChannel?.id,
    itemStatus: docket?.itemStatus,
    source: docket?.source,
    status: docket.status,
  };
  displayDocket.push(orderInfo);
  return displayDocket;
};

export const constructDisplayDocketNotes = (
  docket: Docket,
): DocketDisplayItem => {
  const tempNotes = removeExtraSpaces(docket.notes || '');
  const shortNotes =
    tempNotes.length > 50 ? tempNotes.slice(0, 50) + '...' : tempNotes;
  const orderNote = {
    type: DocketDisplayItemType.ORDER_NOTE,
    name: shortNotes,
    height: DocketDisplayItemTypeHeight[DocketDisplayItemType.ORDER_NOTE],
  };
  return orderNote;
};

export const constructTransferredLabel = (
  tableId: string,
  transferLabelType: DocketDisplayItemType,
): DocketDisplayItem => {
  const transferred = {
    type: transferLabelType,
    name: tableId,
    height: DocketDisplayItemTypeHeight[transferLabelType],
  };
  return transferred;
};

export const constructTransferredItems = (
  docket: Docket,
): DocketDisplayItem[] => {
  const transfers = groupTransfers(docket.docketItems);
  const transferredItems: DocketDisplayItem[] = [];

  transfers.forEach(docketItemsGroup => {
    const [{ transferInfo = {}, orderId }] = docketItemsGroup;
    const {
      toTableId = '',
      fromTableId = '',
      toOrderId = '',
    } = transferInfo as TransferInfo;

    const isTransferredTo = toOrderId !== orderId;
    const transferLabelType = isTransferredTo
      ? DocketDisplayItemType.TRANSFERRED_TO_LABEL
      : DocketDisplayItemType.TRANSFERRED_FROM_LABEL;
    const tableId = isTransferredTo ? toTableId : fromTableId;

    transferredItems.push(
      constructTransferredLabel(tableId, transferLabelType),
    );

    docket.docketItems = docketItemsGroup;
    transferredItems.push(...constructDisplayDocketItems(docket));
  });

  return transferredItems;
};

export const constructDueAtTime = (docket: Docket): DocketDisplayItem => {
  const result = {
    type: DocketDisplayItemType.DUE_AT,
    name: docket.dueAt + '',
    height: DocketDisplayItemTypeHeight[DocketDisplayItemType.DUE_AT],
  };
  return result;
};

export const constructDisplayDocketRecalledLabel = (): DocketDisplayItem => {
  const recallLabel = {
    type: DocketDisplayItemType.RECALLED_TAG,
    name: RECALLED_TEXT,
    height: DocketDisplayItemTypeHeight[DocketDisplayItemType.RECALLED_TAG],
  };
  return recallLabel;
};

export const constructDisplayDocketVoidedStatus = (): DocketDisplayItem => {
  const voidLabel = {
    type: DocketDisplayItemType.VOID,
    name: VOIDED_TEXT,
    height: DocketDisplayItemTypeHeight[DocketDisplayItemType.VOID],
  };
  return voidLabel;
};

export const constructDisplayDocketItems = (
  docket: Docket,
): DocketDisplayItem[] => {
  const coursesAppended: string[] = [];
  return docket?.docketItems
    ?.map((docketItem: DocketItem, index: number) => {
      const docketDisplayItem: DocketDisplayItem[] = [];
      if (docketItem?.course?.id) {
        const course = {
          type: DocketDisplayItemType.COURSE,
          name: docketItem?.course?.name || '',
          height: DocketDisplayItemTypeHeight[DocketDisplayItemType.COURSE],
        };
        if (!coursesAppended.find(c => c == course.name)) {
          coursesAppended.push(course.name);
          docketDisplayItem.push(course as DocketDisplayBaseItem);
        }
      }
      let numberOfLinesForProduct = 1;
      if (docketItem?.product?.name) {
        numberOfLinesForProduct = getNoOfLinesForText(
          docketItem?.product?.name || '',
        );
      }
      const heightAdjustment =
        numberOfLinesForProduct > 1
          ? numberOfLinesForProduct * TextLineHeight
          : 0;
      const item: DocketDisplayOrderItem = {
        type: DocketDisplayItemType.ITEM,
        name: docketItem?.product?.name || '',
        quantity: docketItem?.quantity || 0,
        height:
          DocketDisplayItemTypeHeight[DocketDisplayItemType.ITEM] +
          heightAdjustment,
        docketId: docket.id,
        id: docketItem.id,
        itemStatus: docketItem.itemStatus,
        numberOfLinesForProduct: numberOfLinesForProduct,
        orderItemId: docketItem.orderItemId || '',
      };
      if (docketItem.notes) {
        const numberOfLinesForNotes = getNoOfLinesForText(docketItem?.notes);
        const heightAdjustment =
          numberOfLinesForNotes > 1
            ? numberOfLinesForNotes * TextLineHeight
            : 0;
        item['note'] = docketItem.notes;
        item['height'] =
          item['height'] +
          DocketDisplayItemTypeHeight[DocketDisplayItemType.ITEM + 'NOTE'] +
          heightAdjustment;
        item['numberOfLinesForNotes'] = numberOfLinesForNotes;
      }
      if (docketItem?.modifiers?.length) {
        const modifiers = docketItem?.modifiers.map(modifier => {
          if (modifier.quantity && modifier.quantity > 1)
            return modifier.quantity + ' ✗ ' + modifier.name;
          else return modifier.name || '';
        });
        item['modifiers'] = modifiers;
        item['height'] =
          item['height'] +
          DocketDisplayItemTypeHeight[DocketDisplayItemType.ITEM + 'MODIFIER'] *
            modifiers.length;
      }

      docketDisplayItem.push(item);
      if (
        docket.status == DocketStatus.COMPLETED &&
        index == docket?.docketItems.length - 1 &&
        docket.itemStatus !== DocketItemStatus.VOIDED
      ) {
        const recall = {
          id: docket.id,
          type: DocketDisplayItemType.RECALL_BUTTON,
          name: RECALL_TEXT,
          height:
            DocketDisplayItemTypeHeight[DocketDisplayItemType.RECALL_BUTTON],
        };
        docketDisplayItem.push(recall);
      }
      return docketDisplayItem;
    })
    .flat();
};

export const transformDocketToDocketDisplayItems = (
  docket: Docket,
): DocketDisplayItem[] => {
  if (!docket) return [] as DocketDisplayItem[];
  const [notTransferred, transferred] = partition(
    docket.docketItems,
    item => !item.transferInfo?.fromOrderId || !item.transferInfo.toOrderId,
  );
  docket.docketItems = notTransferred;
  let displayDocket = [];
  displayDocket = constructDisplayDocketHeader(docket);
  if (
    docket.status == DocketStatus.CREATED &&
    docket.isOnline &&
    docket.dueAt
  ) {
    displayDocket.push(constructDueAtTime(docket));
  }
  if (docket.notes)
    displayDocket = [...displayDocket, constructDisplayDocketNotes(docket)];
  if (docket.itemStatus == DocketItemStatus.VOIDED)
    displayDocket = [...displayDocket, constructDisplayDocketVoidedStatus()];
  if (docket.itemStatus == DocketItemStatus.RECALLED)
    displayDocket = [...displayDocket, constructDisplayDocketRecalledLabel()];
  if (docket?.docketItems?.length)
    displayDocket.push(...constructDisplayDocketItems(docket));
  if (transferred?.length) {
    docket.docketItems = transferred;
    displayDocket = [...displayDocket, ...constructTransferredItems(docket)];
  }
  return displayDocket;
};

export const addContinueTags = (displayDockets: DocketDisplayItem[]) => {
  let height = HeaderHeight;
  displayDockets.forEach((displayDocket: DocketDisplayItem, i: number) => {
    height = height + displayDocket?.height || 0;
    const isNotHeader =
      displayDocket?.type != DocketDisplayItemType.DOCKET_HEADER;
    const heightOfDocketView = theme.window.vh - FooterHeight;
    const remianingSpaceHeight = height - heightOfDocketView;
    if (isNotHeader && remianingSpaceHeight > 0) {
      let index = i;
      if (
        remianingSpaceHeight <
        DocketDisplayItemTypeHeight[DocketDisplayItemType.CONTINUE]
      )
        index = i - 1;
      height =
        HeaderHeight +
        DocketDisplayItemTypeHeight[DocketDisplayItemType.CONTINUE];
      displayDockets.splice(
        index,
        0,
        {
          type: DocketDisplayItemType.CONTINUE,
          name: CONTINUE,
          height: DocketDisplayItemTypeHeight[DocketDisplayItemType.CONTINUE],
        },
        {
          type: DocketDisplayItemType.CONTINUED,
          name: CONTINUE,
          height: DocketDisplayItemTypeHeight[DocketDisplayItemType.CONTINUE],
        },
      );
    }
  });
  return displayDockets;
};

export const getOrderTypeName = (id: string, orderTypes: OrderType[]) => {
  return orderTypes.find(orderType => orderType.id == id)?.name || '';
};

export const getPartnerSalesChannelName = (
  id: string,
  salesChannels: SalesChannel[],
) => {
  return (
    salesChannels
      .filter(salesChannel => {
        if (
          salesChannel.name == SALES_CHANNEL.INSTORE ||
          salesChannel.name.toUpperCase() == SALES_CHANNEL.ONLINE
        )
          return false;
        return true;
      })
      .find(salesChannel => salesChannel.id == id)?.name || ''
  );
};

export const getDockerHeaderTitle = (props: {
  orderTypes: OrderType[];
  orderTypeId: string;
  tableName: string;
  sectionName: string;
  customerName: string;
  orderNumber: string;
  source: string;
}): string => {
  if (props.source === App.KIOSK) {
    return (
      props.customerName?.trim() ||
      String(+props.orderNumber.split('-')?.splice(1, 1))
    );
  }

  const DineIn = props.orderTypes.find(
    orderType => orderType.code === OrderTypeCode.DINE_IN,
  );

  if (DineIn && DineIn.id === props.orderTypeId) {
    // Show Table Details for Dine In Orders
    if (props.sectionName) {
      return [props.sectionName, props.tableName].filter(x => x).join(', ');
    }
    return props.tableName;
  }

  if (props.customerName) {
    // Show Customer Name Otherwise
    return startCase(props.customerName?.toLowerCase());
  }

  return DEFAULT_DOCKET_NAME;
};

export const getHeaderColorByOrderType = (
  orderTypeId: string,
  orderTypes: OrderType[],
): string => {
  if (!orderTypeId) return theme.colors.primary;
  const currentOrderType = orderTypes.find(
    orderType => orderType.id == orderTypeId,
  );
  if (currentOrderType && DOCKET_HEADER_COLOR[`${currentOrderType.code}`])
    return DOCKET_HEADER_COLOR[`${currentOrderType.code}`];
  return theme.colors.teal;
};

export const getNoOfLinesForText = (input: string) => {
  if (input.length < TextCharacterCount) return 1;
  return Math.ceil(input.length / TextCharacterCount);
};
