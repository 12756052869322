/* eslint-disable react-native/no-inline-styles */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  View,
  Platform,
  AppState,
  NativeEventSubscription,
  ActivityIndicator,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Nav } from '../../common/types';
import { useSession } from '../../hooks/useSession';
import { useDockets } from '../../hooks/useDockets';
import { useNetworkStatusVar } from '../../common/blocks/NetworkStatus';
import { DocketStatus, PrinterProfile } from '@oolio-group/domain';
import { SegmentControlTabs } from '../../common/types';
import { options, FEATURES } from '../../common/constants';
import { useColors } from '../../hooks/useColors';
import { useSettings } from '../../hooks/useSettings';
import { useFetchSettings } from '../../hooks/useFetchSettings';
import { useSections } from '../../hooks/organization/useSections';
import { getHeaders, headers } from '../../utils/fetchHeaders';
import { openDocketslastUpdatedAt, tokenUpdated } from '../../state/apolloVars';
import { useReactiveVar } from '@apollo/client';
import DocketsLayout from './Dockets/DocketsLayout';
import MainScreenStyles from './MainScreen.styles';
import ButtonIcon from '../../component/Buttons/ButtonIcon';
import SegmentControl from '../../component/SegmentControl/SegmentControl';
import PrinterProfileModal from './PrinterProfile';
import { useDeviceEvent } from '../../hooks/useDeviceEvent';
import { analyticsService } from '../../analytics/AnalyticsService';
import SortingOptions from '../../component/SortingOptions/SortingOptions';
import ButtonText from '../../component/Buttons/ButtonText';
import { useTranslation } from '@oolio-group/localization';
import AllDayView from '../../component/AllDayView/AllDayView';
import { Notification, useNotification } from '../../hooks/useNotification';
import { NotificationBanner } from './Notification';

const MainScreen: React.FC = () => {
  const appState = useRef(AppState.currentState);

  const { fetchSettings, loading: settingsLoading } = useFetchSettings();
  const {
    sections,
    getSectionsRequest,
    loading: sectionsLoading,
  } = useSections();
  const { session, setSession } = useSession();
  const { translate } = useTranslation();
  const { logout } = useSettings();
  const styles = MainScreenStyles();
  const { colors } = useColors();
  const { activeTheme } = useColors();
  const navigation = useNavigation<Nav>();
  const networkStatus = useNetworkStatusVar();
  const tokenUpdatedVar = useReactiveVar(tokenUpdated);
  const storeId = session?.currentStore?.id || '';
  const { getActiveDockets, getCompletedDockets } = useDockets();
  useDeviceEvent();
  const [headers, setHeaders] = useState<headers>();
  const [currentPage, setCurrentPage] = useState(0);
  const [printerProfileIds, setPrinterProfileIds] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<SegmentControlTabs>(
    options[0],
  );

  const [sortViewVisible, setSortViewVisible] = useState<boolean>(false);
  const [allDayViewVisible, setAllDayViewVisible] = useState<boolean>(false);
  const [isAllDayViewEnabled, setIsAllDayViewEnabled] =
    useState<boolean>(false);

  const [isDocketPrintEnabled, setIsDocketPrintEnabled] =
    useState<boolean>(false);

  const { currentNotification, closeNotification: closeAppNotification } =
    useNotification();

  const isSidebarVisible = allDayViewVisible || sortViewVisible;
  const loading = sectionsLoading || settingsLoading;

  useEffect(() => {
    if (!session?.sections?.length && sections?.length) {
      setSession({
        sections: sections,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, setSession]);

  useEffect(() => {
    if (!isAllDayViewEnabled && !loading) {
      const getAllDayViewFlag = async () => {
        const isEnabled = await analyticsService.isFeatureEnabled(
          FEATURES.ALL_DAY_VIEW,
        );
        setIsAllDayViewEnabled(isEnabled);
      };
      getAllDayViewFlag();
    }
  }, [isAllDayViewEnabled, loading]);

  useEffect(() => {
    const getDocketPrintFlag = async () => {
      const isEnabled = await analyticsService.isFeatureEnabled(
        FEATURES.DOCKET_PRINTING,
      );
      const isPrintEnabled = isEnabled && !!session?.kitchenDisplay?.printer;
      setIsDocketPrintEnabled(isPrintEnabled);
    };
    getDocketPrintFlag();
  }, [session?.kitchenDisplay?.printer]);

  const onLogout = useCallback(() => {
    analyticsService.capture('Logged Out');
    if (session?.kitchenDisplay?.id) logout(session?.kitchenDisplay?.id);
  }, [session, logout]);

  const tabOptions = useMemo(() => {
    return options;
  }, []);

  const toggleAllDayView = useCallback(() => {
    if (sortViewVisible && !allDayViewVisible) {
      setSortViewVisible(false);
    }
    setAllDayViewVisible(prev => !prev);
  }, [allDayViewVisible, sortViewVisible]);

  const closeAllDayView = useCallback(() => {
    setAllDayViewVisible(false);
  }, []);

  const toggleSortView = useCallback(() => {
    if (allDayViewVisible && !sortViewVisible) {
      setAllDayViewVisible(false);
    }
    setSortViewVisible(prev => !prev);
  }, [allDayViewVisible, sortViewVisible]);

  const closeSortView = useCallback(() => {
    setSortViewVisible(false);
  }, []);

  const onPressTab = useCallback(
    (tab: DocketStatus) => {
      analyticsService.capture(`Viewing ${tab}`);
      setSelectedTab({
        label: tab,
        value: tab,
      });
      setCurrentPage(0);
      if (tab === DocketStatus.COMPLETED) {
        if (allDayViewVisible) closeAllDayView();
        else if (sortViewVisible) closeSortView();
      }
    },
    [allDayViewVisible, closeAllDayView, closeSortView, sortViewVisible],
  );

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    if (!session?.sections?.length && session?.currentVenue) {
      getSectionsRequest({ variables: { id: session?.currentVenue.id } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSectionsRequest]);

  useEffect(() => {
    getHeaders().then(setHeaders);
  }, [tokenUpdatedVar.timestamp]);

  const onPressRefresh = useCallback(async () => {
    analyticsService.capture('Refresh Requested');
    if (printerProfileIds?.length) {
      selectedTab.value == DocketStatus.CREATED
        ? await getActiveDockets(printerProfileIds, storeId)
        : await getCompletedDockets(printerProfileIds, storeId);
      openDocketslastUpdatedAt(performance.now());
    }
  }, [
    getActiveDockets,
    getCompletedDockets,
    printerProfileIds,
    selectedTab.value,
    storeId,
  ]);

  const onPressSettings = useCallback(() => {
    analyticsService.capture('Navigated to Settings');
    if (session.authorized) {
      navigation.navigate('Settings');
    }
  }, [navigation, session.authorized]);

  useEffect(() => {
    if (printerProfileIds?.length) {
      (async () => {
        try {
          selectedTab.value == DocketStatus.CREATED
            ? await getActiveDockets(printerProfileIds, storeId)
            : await getCompletedDockets(printerProfileIds, storeId);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [
    printerProfileIds,
    getActiveDockets,
    getCompletedDockets,
    selectedTab.value,
    storeId,
  ]);

  useEffect(() => {
    const printerProfileIds = session?.kitchenDisplay?.printerProfiles?.map(
      (printerProfile: PrinterProfile) => printerProfile.id,
    );
    if (printerProfileIds?.length) setPrinterProfileIds(printerProfileIds);
  }, [session?.kitchenDisplay?.printerProfiles]);

  useEffect(() => {
    // this block will render when app moves from inactive or background to foreground
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      const subscription: NativeEventSubscription = AppState.addEventListener(
        'change',
        nextAppState => {
          if (
            /inactive|background/.test(appState.current) &&
            nextAppState === 'active'
          ) {
            selectedTab.value == DocketStatus.CREATED
              ? getActiveDockets(printerProfileIds, storeId)
              : getCompletedDockets(printerProfileIds, storeId);
          }
          appState.current = nextAppState;
        },
      ) as unknown as NativeEventSubscription;
      return () => {
        if (subscription) {
          subscription.remove();
        }
      };
    }
  }, [
    getActiveDockets,
    getCompletedDockets,
    printerProfileIds,
    selectedTab.value,
    storeId,
  ]);

  useEffect(() => {
    analyticsService.identify(session.currentStore?.name as string, {
      theme: activeTheme,
    });
  }, [activeTheme, session.currentStore?.name]);

  const closeNotification = useCallback(
    (notification: Notification) => {
      closeAppNotification(notification.id);
    },
    [closeAppNotification],
  );

  if (loading)
    return (
      <View style={styles.loader}>
        <ActivityIndicator size={'large'} color={colors.text} />
      </View>
    );

  return (
    <>
      <View style={styles.screen} ph-label="main-screen">
        {currentNotification && (
          <NotificationBanner
            notification={currentNotification}
            closeNotification={closeNotification}
          />
        )}
        <View style={styles.header}>
          <View style={styles.buttons}>
            <ButtonIcon
              ignoreTheme
              testID="btn-logout"
              icon="lock-alt"
              type="interface"
              onPress={onLogout}
              containerStyle={styles.buttonGap}
            />
            <ButtonIcon
              ignoreTheme
              testID="btn-networkStatus"
              onPress={onPressRefresh}
              icon={!networkStatus ? 'wifi-slash' : 'wifi'}
              type={!networkStatus ? 'negative' : 'positive'}
            />
          </View>
          <SegmentControl
            testID="segments-docketType"
            tabs={tabOptions}
            onChange={onPressTab}
            selectedTab={selectedTab.value}
          />
          <View style={[styles.buttons, { justifyContent: 'flex-end' }]}>
            {selectedTab.value === DocketStatus.CREATED ? (
              <>
                <ButtonText
                  ignoreTheme
                  testID="btn-sort"
                  icon="sort-amount-down"
                  type={sortViewVisible ? 'active' : 'interface'}
                  onPress={toggleSortView}
                  label={
                    session.sortingPref?.label ||
                    translate('kitchenDisplay.sortOldest')
                  }
                  containerStyle={styles.btnSort}
                />
                {!!isAllDayViewEnabled && (
                  <ButtonIcon
                    ignoreTheme
                    icon="eye"
                    testID="btn-all-day-view"
                    onPress={toggleAllDayView}
                    type={allDayViewVisible ? 'active' : 'interface'}
                    containerStyle={styles.buttonGap}
                  />
                )}
              </>
            ) : null}

            <ButtonIcon
              ignoreTheme
              icon="cog"
              testID="btn-settings"
              type="interface"
              onPress={onPressSettings}
              containerStyle={styles.buttonGap}
            />
            <ButtonIcon
              ignoreTheme
              icon="sync"
              testID="btn-refresh"
              type="interface"
              onPress={onPressRefresh}
            />
          </View>
        </View>
        <View
          style={[styles.content, isSidebarVisible && styles.sidebarMargin]}
        >
          {headers && printerProfileIds?.length ? (
            <DocketsLayout
              headers={headers}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              docketStatus={selectedTab.value}
              enablePrint={isDocketPrintEnabled}
            />
          ) : null}
        </View>
        <PrinterProfileModal />
      </View>
      <AllDayView visible={allDayViewVisible} onClose={closeAllDayView} />
      {sortViewVisible && selectedTab.value === DocketStatus.CREATED ? (
        <SortingOptions onClose={closeSortView} />
      ) : null}
    </>
  );
};

export default MainScreen;
