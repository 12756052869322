import { DocketItemStatus, DocketStatus } from '@oolio-group/domain';

export interface SegmentControlTabs {
  label: string;
  value: string | DocketStatus;
  badge?: string | number;
}

export const DEFAULT_DOCKET_NAME = 'Guest';

export enum DocketDisplayItemType {
  DOCKET_HEADER = 'DOCKET_HEADER',
  ORDER_NOTE = 'ORDER_NOTE',
  ITEM = 'ITEM',
  COURSE = 'COURSE',
  CONTINUE = 'CONTINUE',
  CONTINUED = 'CONTINUED',
  RECALL_BUTTON = 'RECALL_BUTTON',
  VOID = 'VOID',
  RECALLED_TAG = 'RECALLED_TAG',
  DUE_AT = 'DUE_AT',
  TRANSFERRED_TO_LABEL = 'TRANSFERRED_TO_LABEL',
  TRANSFERRED_FROM_LABEL = 'TRANSFERRED_FROM_LABEL',
}

export interface DocketDisplayOrder {
  id: string;
  type: DocketDisplayItemType;
  name: string;
  orderNumber: string;
  orderType: string;
  time: string;
  height: number;
  section: string;
  table: string;
  customer: string;
  salesChannel: string;
  isLastItemToBump: boolean;
  status: DocketStatus;
  source: string;
}
export interface DocketDisplayBaseItem {
  type: DocketDisplayItemType;
  name: string;
  height: number;
}
export interface DocketDisplayOrderItem extends DocketDisplayBaseItem {
  quantity: number;
  docketId: string;
  id: string;
  note?: string;
  modifiers?: string[];
  variant?: string;
  itemStatus: DocketItemStatus;
  numberOfLinesForNotes?: number;
  numberOfLinesForProduct: number;
  orderItemId: string;
}

export interface AllDayViewItem {
  product?: string;
  variation?: string;
  course?: string;
  quantity?: number;
  modifiers?: string[];
  docketItemIds?: string[];
  orderItemIds?: string[];
}

export type DocketDisplayItem = DocketDisplayOrderItem | DocketDisplayBaseItem;

export enum DocketSource {
  API = 'API',
  SUBSCRIPTION = 'SUBSCRIPTION',
  POOLING = 'POOLING',
}

export type DocketUpdate = {
  timestamp: number;
  source?: DocketSource;
  triggerSound?: boolean;
};

export type PageDimension = {
  startHeight: number;
  endHeight: number;
};

export type DocketHeaderColor = {
  [key: string]: string;
};

export type Nav = {
  navigate: (value: string) => void;
};

export enum Scale {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}
